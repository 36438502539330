import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import * as _prettierBytes2 from "@transloadit/prettier-bytes";

var _prettierBytes = "default" in _prettierBytes2 ? _prettierBytes2.default : _prettierBytes2;

import * as _truncateString2 from "@uppy/utils/lib/truncateString";

var _truncateString = "default" in _truncateString2 ? _truncateString2.default : _truncateString2;

var exports = {};
var _require = _preact,
    h = _require.h;
var prettierBytes = _prettierBytes;
var truncateString = _truncateString;

var renderAcquirerIcon = function renderAcquirerIcon(acquirer, props) {
  return h("span", {
    title: props.i18n("fileSource", {
      name: acquirer.name
    })
  }, acquirer.icon());
};

var renderFileName = function renderFileName(props) {
  // Take up at most 2 lines on any screen
  var maxNameLength; // For very small mobile screens

  if (props.containerWidth <= 352) {
    maxNameLength = 35; // For regular mobile screens
  } else if (props.containerWidth <= 576) {
    maxNameLength = 60; // For desktops
  } else {
    maxNameLength = 30;
  }

  return h("div", {
    className: "uppy-Dashboard-Item-name",
    title: props.file.meta.name
  }, truncateString(props.file.meta.name, maxNameLength));
};

var renderFileSize = function renderFileSize(props) {
  return props.file.size && h("div", {
    className: "uppy-Dashboard-Item-statusSize"
  }, prettierBytes(props.file.size));
};

var ReSelectButton = function ReSelectButton(props) {
  return props.file.isGhost && h("span", null, " \u2022 ", h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-Dashboard-Item-reSelect",
    type: "button",
    onClick: props.toggleAddFilesPanel
  }, props.i18n("reSelect")));
};

var ErrorButton = function ErrorButton(_ref) {
  var file = _ref.file,
      onClick = _ref.onClick;

  if (file.error) {
    return h("span", {
      className: "uppy-Dashboard-Item-errorDetails",
      "aria-label": file.error,
      "data-microtip-position": "bottom",
      "data-microtip-size": "medium",
      role: "tooltip",
      onClick: onClick
    }, "?");
  }

  return null;
};

exports = function FileInfo(props) {
  return h("div", {
    className: "uppy-Dashboard-Item-fileInfo",
    "data-uppy-file-source": props.file.source
  }, renderFileName(props), h("div", {
    className: "uppy-Dashboard-Item-status"
  }, renderFileSize(props), ReSelectButton(props), h(ErrorButton, {
    file: props.file,
    onClick: function onClick() {
      alert(props.file.error);
    }
  })));
};

export default exports;