import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import _AddFiles from "./AddFiles";
var exports = {};
var _require = _preact,
    h = _require.h;
var classNames = _classnames;
var AddFiles = _AddFiles;

var AddFilesPanel = function AddFilesPanel(props) {
  return h("div", {
    className: classNames("uppy-Dashboard-AddFilesPanel", props.className),
    "data-uppy-panelType": "AddFiles",
    "aria-hidden": props.showAddFilesPanel
  }, h("div", {
    className: "uppy-DashboardContent-bar"
  }, h("div", {
    className: "uppy-DashboardContent-title",
    role: "heading",
    "aria-level": "1"
  }, props.i18n("addingMoreFiles")), h("button", {
    className: "uppy-DashboardContent-back",
    type: "button",
    onClick: function onClick(ev) {
      return props.toggleAddFilesPanel(false);
    }
  }, props.i18n("back"))), h(AddFiles, props));
};

exports = AddFilesPanel;
export default exports;