import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import _FilePreview from "../../FilePreview";
import _getFileTypeIcon from "../../../utils/getFileTypeIcon";
var exports = {};
var _require = _preact,
    h = _require.h;
var FilePreview = _FilePreview;
var getFileTypeIcon = _getFileTypeIcon;

exports = function FilePreviewAndLink(props) {
  return h("div", {
    className: "uppy-Dashboard-Item-previewInnerWrap",
    style: {
      backgroundColor: getFileTypeIcon(props.file.type).color
    }
  }, props.showLinkToFileUploadResult && props.file.uploadURL && h("a", {
    className: "uppy-Dashboard-Item-previewLink",
    href: props.file.uploadURL,
    rel: "noreferrer noopener",
    target: "_blank",
    "aria-label": props.file.meta.name
  }), h(FilePreview, {
    file: props.file
  }));
};

export default exports;