import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import * as _classnames2 from "classnames";

var _classnames = "default" in _classnames2 ? _classnames2.default : _classnames2;

import _ignoreEvent from "../utils/ignoreEvent.js";
var exports = {};
var _require = _preact,
    h = _require.h;
var classNames = _classnames;
var ignoreEvent = _ignoreEvent;

function PickerPanelContent(props) {
  return h("div", {
    className: classNames("uppy-DashboardContent-panel", props.className),
    role: "tabpanel",
    "data-uppy-panelType": "PickerPanel",
    id: "uppy-DashboardContent-panel--" + props.activePickerPanel.id,
    onDragOver: ignoreEvent,
    onDragLeave: ignoreEvent,
    onDrop: ignoreEvent,
    onPaste: ignoreEvent
  }, h("div", {
    className: "uppy-DashboardContent-bar"
  }, h("div", {
    className: "uppy-DashboardContent-title",
    role: "heading",
    "aria-level": "1"
  }, props.i18n("importFrom", {
    name: props.activePickerPanel.name
  })), h("button", {
    className: "uppy-DashboardContent-back",
    type: "button",
    onClick: props.hideAllPanels
  }, props.i18n("cancel"))), h("div", {
    className: "uppy-DashboardContent-panelBody"
  }, props.getPlugin(props.activePickerPanel.id).render(props.state)));
}

exports = PickerPanelContent;
export default exports;